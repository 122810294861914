<template>
	<div
		class="box pa-20 min-height-350 overflow-y-auto"
	>
		<div :inner-html.prop="auth_info | nl2br"></div>
	</div>
</template>

<script>
	export default {
		name: 'AuthGuide'
		, props: ['user', 'shop_info']
		, data: function(){
			return {
				program: {
					name: '이용약관'
					, top: false
					, title: true
					, bottom: true
					, from: 'Cart'
					, top_line: true
				}
			}
		}
		, computed: {
			auth_info: function(){
				let t = ''
				if(this.$route.params.type == 'terms'){
					t = this.shop_info.site_terms
				}else if(this.$route.params.type == 'personal'){
					t = this.shop_info.site_personal
				}
				return t
			}
			, title: function(){
				let t = ''
				if(this.$route.params.type == 'terms'){
					t = '이용 약관'
				}else if(this.$route.params.type == 'personal'){
					t = '개인정보 처리방침'
				}
				return t
			}
		}
		, created() {

			this.program.name = this.title
			this.$bus.$emit('onLoad', this.program)
		}
	}
</script>